import React from "react";
import styled from "styled-components";
import { H2, TextLarge, TextLargeBold, TextMedium, TextMediumBold } from "../theme/typography";
import { Row } from "../components/Layout";
import { IconButton } from "../components/Buttons";
import { IconInput } from "../components/Inputs";
import Plus from "../assets/plus.svg";
import Search from "../assets/search.svg";

const StyledGroupAdmin = styled.div``;

const GroupAdmin = () => {
  const handleClick = () => {
    console.log("hello");
  };

  const handleChange = (e: any) => {
    console.log("val", e.target.value);
  };

  return (
    <StyledGroupAdmin>
      <Row justifyContent="space-between" alignItems="center">
        <H2>Manage Groups</H2>
        <IconButton
          onClick={(e: any) => handleClick()}
          role="add"
          size="lg"
          text="Create Group"
          icon={Plus}
        ></IconButton>
      </Row>
      <IconInput placeholder="Search" onChange={(e: any) => handleChange(e)} icon={Search} />
    </StyledGroupAdmin>
  );
};

export default GroupAdmin;
