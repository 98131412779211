import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    blue: {
      primary: "#0D6EFF",
      active: "#0051CB"
    },
    grey: {
      primary: "#353A47",
      active: "#1E212B"
    }
    // grey: {
    //   800: "#30343F",
    //   700: "#4B4F5B",
    //   600: "#9094A4",
    //   500: "#B3B5C0",
    //   400: "#CCCED8",
    //   300: "#E7E9F0",
    //   200: "#F5F6FA",
    //   100: "#FFFFFF"
    // }
  },
  gradients: {
    grey: {
      900: ["#272E40", "#1B1C28"],
      800: ["#3C414A", "#0B0C10"],
      700: ["#4B4F5B", "#2C2E35"]
    }
  }
};

export default theme;
