import React, { useState } from "react";
import styled from "styled-components";
import { IconInput } from "../components/Inputs";
import edit from "../assets/edit.svg";
import users from "../users.json";
import Fuse from "fuse.js";
import { TextSmall } from "../theme/typography";

const UserRow = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }

  .ghost {
    opacity: 50%;
  }
`;

const FuncModalBody = (props: any) => {
  const [results, setResults] = useState([]);

  const fuse = new Fuse(users, { keys: ["name", "email", "location"], includeScore: true });

  const handleChange = (e: any) => {
    const str = e.target.value;
    const result = fuse.search(str);
    //@ts-ignore
    setResults(result.sort((a, b) => a.score > b.score).map((el) => el.item));
  };

  return (
    <div>
      <IconInput placeholder="Search" onChange={(e: any) => handleChange(e)} />
      {results.map((el: any, i: number) => {
        //@ts-ignore
        return (
          <UserRow key={i}>
            <TextSmall>{el.name}</TextSmall>
            <TextSmall className="ghost">{el.email}</TextSmall>
          </UserRow>
        );
      })}
    </div>
  );
};

export default FuncModalBody;
