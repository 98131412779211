import React, { ReactNode } from "react";
import styled from "styled-components";

interface RowProps {
  justifyContent?: string;
  alignItems?: string;
  children: ReactNode;
}

const StyledRow = styled.div<RowProps>`
  display: flex;
  justify-content: ${(p) => p.justifyContent || "flex-start"};
  align-items: ${(p) => p.alignItems || "flex-start"};
`;

export const Row = (props: RowProps) => {
  return (
    <StyledRow justifyContent={props.justifyContent} alignItems={props.alignItems}>
      {props.children}
    </StyledRow>
  );
};
