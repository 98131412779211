import React, { useState } from "react";
import styled from "styled-components";
import { H2, TextSmallBold } from "../theme/typography";
import { Row } from "../components/Layout";
import { IconButton } from "../components/Buttons";
import { IconInput } from "../components/Inputs";
import Plus from "../assets/plus.svg";
import Search from "../assets/search.svg";
import Modal from "react-modal";
import users from "../users.json";
import close from "../assets/close.svg";
import UserRow from "../components/UserRow";
import FuncModalBody from "../components/FuncModalBody";

const StyledUserAdmin = styled.div``;

Modal.setAppElement("#root");

const UserAdmin = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChange = (e: any) => {
    console.log("val", e.target.value);
  };

  const modalStyles = {
    overlay: {},
    content: {
      border: "none"
      // filter: "dropShadow(0px 4px 44px rgba(0, 0, 0, 0.25))",
      // backdropFilter: "blur(70px)",
    }
  };

  const ModalContent = styled.div`
    /* background-color: rgba(255, 255, 255, 0.25); */
    height: 100%;
    display: flex;
    flex-direction: column;
  `;

  const ModalHeader = styled.div`
    background-color: rgba(255, 255, 255, 0.08);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;

    img {
      cursor: pointer;
      padding: 0.5rem;
      transform: translateY(-0.5rem);
    }
  `;

  const ModalBody = styled.div`
    flex: 1;
    background-color: rgba(255, 255, 255, 0.08);
    padding: 1rem;
    padding-top: 0;
  `;

  const ModalFooter = styled.div`
    background-color: rgba(255, 255, 255, 0.06);
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    button:first-of-type {
      margin-right: 1rem;
    }
  `;

  const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 -1rem;
    padding: 1rem;

    div {
      width: 25%;
    }
  `;

  return (
    <StyledUserAdmin>
      <Modal
        closeTimeoutMS={300}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        // style={modalStyles}
        contentLabel="Example Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <ModalContent>
          <ModalHeader>
            <span>Add User</span>
            <img src={close} alt="close icon" onClick={() => closeModal()} />
          </ModalHeader>
          <ModalBody>
            <FuncModalBody />
          </ModalBody>
          <ModalFooter>
            <IconButton
              onClick={(e: any) => closeModal()}
              role="add"
              size="lg"
              text="Add"
            ></IconButton>
            <IconButton
              onClick={(e: any) => closeModal()}
              role="primary"
              size="lg"
              text="Cancel"
            ></IconButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Row justifyContent="space-between" alignItems="center">
        <H2>Manage Users</H2>
        <IconButton
          onClick={(e: any) => handleClick()}
          role="add"
          size="lg"
          text="Add User"
          icon={Plus}
        ></IconButton>
      </Row>
      <IconInput placeholder="Search" onChange={(e: any) => handleChange(e)} icon={Search} />
      <TitleRow>
        <span style={{ width: "40px" }}></span>
        <TextSmallBold>Name</TextSmallBold>
        <TextSmallBold>Email</TextSmallBold>
        <TextSmallBold>Feeds</TextSmallBold>
        <TextSmallBold>Role</TextSmallBold>
      </TitleRow>
      {users.map((user: any) => {
        return <UserRow user={user} />;
      })}
    </StyledUserAdmin>
  );
};

export default UserAdmin;
