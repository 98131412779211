import React from "react";
import styled from "styled-components";

const StyledHome = styled.div``;

const Home = () => {
  return (
    <StyledHome>
      <h3>Home</h3>
    </StyledHome>
  );
};

export default Home;
