import React from "react";
import styled from "styled-components";
import Sidebar from "../components/Sidebar";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  background: linear-gradient(169.19deg, #272e40 3.18%, #1b1c28 77.29%);
`;

const Main = styled.div`
  width: 100%;
  /* background: ${({ theme }) =>
    `linear-gradient(${theme.gradients.grey["700"][0]}, ${theme.gradients.grey["700"][1]});`}; */
  padding: 2rem;
`;

const Layout = (props: any) => {
  return (
    <StyledLayout>
      <Sidebar />
      <Main>{props.children}</Main>
    </StyledLayout>
  );
};

export default Layout;
