import React from "react";
import styled from "styled-components";

import SidebarRow from "./SidebarRow";
import logo from "../assets/logo.svg";
import sublogo from "../assets/sublogo.svg";
import today from "../assets/today.svg";
import content from "../assets/content.svg";
import reading from "../assets/reading.svg";
import recent from "../assets/recent.svg";
import settings from "../assets/settings.svg";
import groups from "../assets/groups.svg";
import users from "../assets/users.svg";

const StyledSidebar = styled.div`
  /* background: ${({ theme }) =>
    `linear-gradient(${theme.gradients.grey["800"][0]}, ${theme.gradients.grey["800"][1]});`}; */
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 100%;
  padding: 2rem;

  section {
    padding: 2rem 0;
  }

  section:first-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const LogoGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  img {
    margin: auto 0 0 5px;
  }
`;

const sidebarRows = [
  { text: "Today", icon: today, path: "" },
  { text: "Curated Content", icon: content, path: "curated-content" },
  { text: "Reading List", icon: reading, path: "reading-list" },
  { text: "Recently Read", icon: recent, path: "recently-read" }
];

const settingsRows = [
  { text: "Settings", icon: settings, path: "settings" },
  { text: "Manage Groups", icon: groups, path: "groups" },
  { text: "Manage Users", icon: users, path: "users" }
];

const Sidebar = () => {
  return (
    <StyledSidebar>
      <LogoGroup>
        <img src={logo} alt="logo" />
        <img src={sublogo} alt="sublogo" />
      </LogoGroup>
      <section>
        {sidebarRows.map((row) => (
          <SidebarRow row={row} />
        ))}
      </section>
      <section>
        {settingsRows.map((row) => (
          <SidebarRow row={row} />
        ))}
      </section>
    </StyledSidebar>
  );
};

export default Sidebar;
