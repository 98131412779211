import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

type Props = {
  row: RowProps;
};

type RowProps = {
  text: string;
  icon: string;
  path: string;
};

const StyledSidebarRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;

  img {
    margin-right: 0.66rem;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
`;

const SidebarRow = ({ row }: Props) => {
  return (
    <Link to={row.path}>
      <StyledSidebarRow>
        <img src={row.icon} />
        <span>{row.text}</span>
      </StyledSidebarRow>
    </Link>
  );
};

export default SidebarRow;
