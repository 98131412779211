import React from "react";
import styled, { css } from "styled-components";

const Text = styled.div`
  font-family: "Mulish";
`;

const TextBold = styled.div`
  font-family: "MulishBold";
`;

const bold = css`
  font-family: "MulishBold";
`;

const _H1 = styled(TextBold)`
  font-size: 32px;
  line-height: 32px;
`;

const _H2 = styled(TextBold)`
  font-size: 22px;
  line-height: 22px;
`;

const _H3 = styled(TextBold)`
  font-size: 18px;
  line-height: 18px;
`;

const _TextLarge = styled(Text)`
  font-size: 14px;
  line-height: 14px;
`;

const _TextLargeBold = styled(_TextLarge)`
  ${bold}
`;

const _TextMedium = styled(Text)`
  font-size: 12px;
  line-height: 12px;
`;

const _TextMediumBold = styled(_TextMedium)`
  ${bold}
`;

const _TextSmall = styled(Text)`
  font-size: 10px;
  line-height: 10px;
`;

const _TextSmallBold = styled(_TextSmall)`
  ${bold}
`;

export const H1 = (props: any) => {
  return <_H1>{props.children}</_H1>;
};

export const H2 = (props: any) => {
  return <_H2>{props.children}</_H2>;
};
export const H3 = (props: any) => {
  return <_H3>{props.children}</_H3>;
};

export const TextLarge = (props: any) => {
  return <_TextLarge>{props.children}</_TextLarge>;
};

export const TextLargeBold = (props: any) => {
  return <_TextLargeBold>{props.children}</_TextLargeBold>;
};

export const TextMedium = (props: any) => {
  return <_TextMedium>{props.children}</_TextMedium>;
};

export const TextMediumBold = (props: any) => {
  return <_TextMediumBold>{props.children}</_TextMediumBold>;
};

export const TextSmall = (props: any) => {
  return <_TextSmall>{props.children}</_TextSmall>;
};

export const TextSmallBold = (props: any) => {
  return <_TextSmallBold>{props.children}</_TextSmallBold>;
};
