// globalStyles.js
import { createGlobalStyle } from "styled-components";

import MulishRegular from "../assets/Mulish-Regular.ttf";
import MulishBold from "../assets/Mulish-Bold.ttf";

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Mulish';
    src: url(${MulishRegular});
  }

  @font-face {
    font-family: 'MulishBold';
    src: url(${MulishBold});
  }

  html {
    min-height: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    min-height: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: white;
    font-family: 'Mulish';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    min-height: 100%;
    height: 100%;
  }

  a,
  a:visited,
  a:link,
  a:focus,
  a:active {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

export default GlobalStyle;
