import React from "react";
import styled from "styled-components";

type InputProps = {
  onChange: Function;
  placeholder: string;
  icon?: string;
};

const Wrapper = styled.div`
  margin-top: 1rem;
  position: relative;

  .icon {
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 0;
    top: 50%;
    margin-top: -8px;
    left: 10px;
    opacity: 0.3;
  }
`;

const Input = styled.input<InputProps>`
  border: 0;
  border-radius: 20px;
  color: #ccc;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.75rem 1rem 0.75rem 2rem;
  border-radius: 12px;
  /* background-color: ${(p) => p.theme.colors.grey.primary}; */
  background-color: rgba(0, 0, 0, 0.12);
  width: 100%;
  box-sizing: border-box;
`;

export const IconInput = (props: InputProps) => {
  return (
    <Wrapper>
      {props.icon && <img className="icon" src={props.icon} />}
      <Input
        onChange={(e) => props.onChange(e)}
        placeholder={props.placeholder}
        icon={props.icon}
      ></Input>
    </Wrapper>
  );
};
