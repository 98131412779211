import React, { ReactNode } from "react";
import styled from "styled-components";

type ButtonTypes = "add" | "save" | "information" | "delete" | "primary" | "secondary";
type ButtonSizes = "sm" | "md" | "lg";

type ButtonProps = {
  onClick: Function;
  role: ButtonTypes;
  size: ButtonSizes;
  text?: string;
  icon?: string;
};

const functionColorMap: any = {
  add: "blue",
  primary: "grey"
};

const sizePaddingMap: any = {
  lg: "0.5rem 1rem",
  md: "0.25rem .5rem",
  sm: ".25rem"
};

const Button = styled.button<ButtonProps>`
  border: 0;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  padding: ${(p) => sizePaddingMap[p.size]};
  background-color: ${(p) => p.theme.colors[functionColorMap[p.role]].primary};

  &:hover,
  &:active {
    background-color: ${(p) => p.theme.colors[functionColorMap[p.role]].active};
  }

  img {
    margin-left: 0.4rem;
  }
`;

export const IconButton = (props: ButtonProps) => {
  return (
    <Button onClick={() => props.onClick()} role={props.role} size={props.size}>
      {props.text}
      {props.icon && <img src={props.icon} />}
    </Button>
  );
};
