import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import avatar from "../assets/avatar.png";
import { TextSmallBold, TextSmall } from "../theme/typography";

type Props = {
  user: UserProps;
};

type UserProps = {
  name: string;
  title: string;
  email: string;
  location: string;
};

const StyledUserRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -1rem;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  div {
    width: 25%;
  }

  img {
    margin-right: 0.66rem;
    width: 25px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }

  &:last-child {
    border-bottom: none;
  }
`;

const SidebarRow = ({ user }: Props) => {
  return (
    <StyledUserRow>
      <img src={avatar} />
      <TextSmallBold>{user.name}</TextSmallBold>
      <TextSmall>{user.email}</TextSmall>
    </StyledUserRow>
  );
};

export default SidebarRow;
