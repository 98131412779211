import React from "react";
import styled from "styled-components";

const StyledNoMatch = styled.div``;

const NoMatch = () => {
  return (
    <StyledNoMatch>
      <h3>Page Not Found</h3>
    </StyledNoMatch>
  );
};

export default NoMatch;
